import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <h1>Contact</h1>
    <p>
      Email: <a href="mailto:nedc.cannon@gmail.com">nedc.cannon@gmail.com</a>
    </p>
    <p>
      LinkedIn:{" "}
      <a
        href="https://www.linkedin.com/in/ned-cannon-b356132/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ned Cannon
      </a>
    </p>
  </Layout>
)

export default SecondPage
