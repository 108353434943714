exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-glasbox-js": () => import("./../../../src/pages/work/glasbox.js" /* webpackChunkName: "component---src-pages-work-glasbox-js" */),
  "component---src-pages-work-hme-ex-js": () => import("./../../../src/pages/work/hme-ex.js" /* webpackChunkName: "component---src-pages-work-hme-ex-js" */),
  "component---src-pages-work-hme-gamify-js": () => import("./../../../src/pages/work/hme-gamify.js" /* webpackChunkName: "component---src-pages-work-hme-gamify-js" */),
  "component---src-pages-work-jaxjox-js": () => import("./../../../src/pages/work/jaxjox.js" /* webpackChunkName: "component---src-pages-work-jaxjox-js" */),
  "component---src-pages-work-microsoft-js": () => import("./../../../src/pages/work/microsoft.js" /* webpackChunkName: "component---src-pages-work-microsoft-js" */),
  "component---src-pages-work-mytennisroster-copy-js": () => import("./../../../src/pages/work/mytennisroster copy.js" /* webpackChunkName: "component---src-pages-work-mytennisroster-copy-js" */),
  "component---src-pages-work-mytennisroster-js": () => import("./../../../src/pages/work/mytennisroster.js" /* webpackChunkName: "component---src-pages-work-mytennisroster-js" */),
  "component---src-pages-work-oxygen-js": () => import("./../../../src/pages/work/oxygen.js" /* webpackChunkName: "component---src-pages-work-oxygen-js" */),
  "component---src-pages-work-oxygen-learning-js": () => import("./../../../src/pages/work/oxygen-learning.js" /* webpackChunkName: "component---src-pages-work-oxygen-learning-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

