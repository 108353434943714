import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic3 from "../../images/ms/ms-logo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .rings {
    max-width: 600px;
  }
  .screens {
    margin-top: 0;
    max-width: 800px;
    margin: 0 auto;
  }
  .link {
    color: #52aabf;
  }
  .client-logo {
    height: 40px;
  }
  .embed-container {
    margin-bottom: 1.5rem;
  }
  .screens p {
    max-width: 800px;
  }
  hr {
    margin: 2.5rem 0 3rem 0;
  }
  h4 {
    margin-top: 2rem;
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/ms/MS_Project_Hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ program management</p>
          </div>
          <div className="desc">
            <h2>Sales Enablement Video Program</h2>
            <p>
              Supporting global Microsoft sales organization through solution
              overview video creative direction and production
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Video Program Management</li>
          <li>Creative Direction</li>
          <li>Vendor Management</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Position Overview:</h5>
        <p>
          Industry Solutions Delivery Group (ISD) is the AI transformation
          leader for Microsoft, partnering with customers and partners on
          cutting-edge solutions that solve complex business needs. ISD
          Consultants apply technical and strategic depth to accelerate
          commercial cloud growth and drive digital and AI transformation for
          enterprise customers.
        </p>
        <p>
          As a full-time contract vendor, I worked directly with Microsoft
          engagement teams in support of high value sales pursuits. I led and
          grew the Solution Video and Demo Program, where I partnered with
          Pursuit Managers and Consulting Leads to tell concise, compelling
          solution narratives through rich video presentations and
          scenario-driven demos.
        </p>
      </div>
      <div className="highlights">
        <h5>ROLE & RESPONSIBILITIES:</h5>
        <ul>
          <li>
            Develop a core familiarity of ISD's technology solution offerings,
            and how they are messaged/positioned across industry verticals
          </li>
          <li>
            Conduct discovery sessions with key Microsoft stakeholders and write
            creative briefs to capture the opportunity and outline the narrative
            approach
          </li>
          <li>
            Manage script-writing process across multiple SMEs and Pursuit
            Managers, driving alignment of messages while identifying key BDM
            audience(s)
          </li>
          <li>
            Storyboard the video/demo experience with core storytelling
            ingredients such as Microsoft brand elements, voice-over narration,
            visual artifacts, video footage, and motion design
          </li>
          <li>
            Creative direct and manage video post-production by external agency
            partners
          </li>
          <li>
            Drive economies of scale by developing standardized script authoring
            tools and repeatable visual design template systems
          </li>
          <li>
            Manage project schedule, all phases of development, stakeholder
            reviews, feedback and campaign landing strategy
          </li>
        </ul>
      </div>
      <br />
      <div className="screens">
        <div class="embed-container">
          <iframe
            src="https://www.youtube.com/embed/c3c4b6i7U6A"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          ></iframe>
        </div>
        <h4>Revolutionizing Mobility: The AI-Driven Future</h4>
        <p>
          The AI-Driven Future The Mobility industry is undergoing a
          transformative shift and how AI is changing how vehicles are designed,
          manufactured, sold and used.
        </p>
        <hr></hr>
        <div class="embed-container">
          <iframe
            src="https://www.youtube.com/embed/Ert6_uHXe9E"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          ></iframe>
        </div>
        <h4>Modern HR with AI: Accelerate AI transformation</h4>
        <p>
          Copilot Dashboard helps leaders measure Copilot usage, identify
          transformative opportunities, and quantify time savings by comparing
          behaviors before and after adoption.
        </p>
        <hr></hr>
        <div class="embed-container">
          <iframe
            src="https://www.youtube.com/embed/tcW7IoiXwgU"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          ></iframe>
        </div>
        <h4>Copilot 365 Enhanced with Viva</h4>
        <p>
          Microsoft Viva, built into Teams and Outlook, enhances the digital
          experience by enabling employees to complete online courses and access
          actionable insights.
        </p>
        <hr></hr>
        <div class="embed-container">
          <iframe
            src="https://player.vimeo.com/video/1046946053"
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
        <h4>Microsoft Azure Power Apps Overview</h4>
        <p>
          An overview of the benefits of low-code Power Apps that make business
          innovations safe and scalable while reducing development costs.
        </p>
        <hr></hr>
        <div class="embed-container">
          <iframe
            src="https://www.youtube.com/embed/r_klW8UH2IU"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameborder="0"
            allowFullScreen
          ></iframe>
        </div>
        <h4>AI Solutions and Data Platforms for Aviation Industry</h4>
        <p>
          Case studies for innovative AI solutions and a new industry reference
          architecture for airlines and airports.
        </p>
        <hr></hr>
      </div>
      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/hme-ex/">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
