import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./global.css"
import logo from "../images/ned-cannon-logo.svg"

const Header = ({ siteTitle }) => (
  <header>
    <nav>
      <Link to="/">
        <img className="img-vector" alt="images" src={logo} />
      </Link>
      <div>
        <Link to="/" activeClassName="active">
          Recent Work
        </Link>
        <Link to="/about/" activeClassName="active">
          About
        </Link>
        <Link to="/contact/" activeClassName="active">
          Contact
        </Link>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
